import { ConfigApi } from '@backstage/core-plugin-api';
import {
  RadarEntry,
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage-community/plugin-tech-radar';
import { QUADRANT_TYPE, RING_TYPE } from '../constants';

const rings = [
  { id: RING_TYPE.ADOPT, name: 'ADOPT', color: '#93c47d' },
  { id: RING_TYPE.TRIAL, name: 'TRIAL', color: '#93d2c2' },
  { id: RING_TYPE.ASSESS, name: 'ASSESS', color: '#fbdb84' },
  { id: RING_TYPE.HOLD, name: 'HOLD', color: '#efafa9' },
];

const quadrants = [
  { id: QUADRANT_TYPE.INFRASTRUCTURE, name: 'Infrastructure' },
  { id: QUADRANT_TYPE.FRAMEWORKS, name: 'Frameworks' },
  { id: QUADRANT_TYPE.LANGUAGES, name: 'Languages' },
  { id: QUADRANT_TYPE.PROCESS, name: 'Process' },
];

interface TechRadarEntryResponse {
  status: string;
  entries: RadarEntry[];
}

export class CustomTechRadarApi implements TechRadarApi {
  apiEndpoint: string;
  constructor(config: ConfigApi) {
    this.apiEndpoint = config.get('backend.baseUrl');
  }
  async load() {
    const techradarResponse = await fetch(
      `${this.apiEndpoint}/api/techradar/data`,
    );
    const techradarEntries =
      (await techradarResponse.json()) as TechRadarEntryResponse;

    const techRadar: TechRadarLoaderResponse = {
      entries: techradarEntries.entries,
      quadrants,
      rings,
    };

    return techRadar;
  }
}
