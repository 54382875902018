// Base imports to build the home page
import {  
  HomePageToolkit,  
  HomePageStarredEntities,  
  TemplateBackstageLogoIcon,  
} from '@backstage/plugin-home';  
import { Content, Page } from '@backstage/core-components';  
import { Grid, makeStyles } from '@material-ui/core';  
import React from 'react';

import { SearchContextProvider } from '@backstage/plugin-search-react';  
import { Warning } from '@material-ui/icons';  
  
const useBannerStyles = makeStyles(theme => ({  
  container: {  
    backgroundColor: theme.palette.warning.light,  
    color: theme.palette.warning.contrastText,  
    padding: theme.spacing(2),  
    display: 'flex',  
    alignItems: 'center',  
    justifyContent: 'center',  
    borderRadius: 5,  
    marginTop: theme.spacing(3),  
  },  
  icon: {  
    marginRight: theme.spacing(1),  
  },  
}));  
  
// Banner
const WarningBanner: React.FC = () => {  
  const classes = useBannerStyles();  
  return (  
    <div className={classes.container}>  
      <Warning className={classes.icon} />  
      This page is still in development! Keep checking for more details tm8s!  
    </div>  
  );  
};  
  
export const GuidePage = () => {  
  
  // Direct links to useful locations, which you can change to whatever you want
  const tools = [  
    {  
      url: '/create/actions',  
      label: 'Create/Actions',  
      icon: <TemplateBackstageLogoIcon />,  
    },  
    {  
      url: '/docs',  
      label: 'Docs',  
      icon: <TemplateBackstageLogoIcon />,  
    },  
    {  
      url: '/catalog?filters%5Bkind%5D=user',  
      label: 'User Catalog',  
      icon: <TemplateBackstageLogoIcon />,  
    },  
    {  
      url: '/catalog?filters%5Bkind%5D=group',  
      label: 'Group Catalog',  
      icon: <TemplateBackstageLogoIcon />,  
    },  
  ];  
  
  // Use the search bar and starred entities as is
  return (  
    <SearchContextProvider>  
      <Page themeId="home">  
        <Content>  
          <Grid container justifyContent="center" spacing={6}>
             
            <Grid container item xs={12}>  
              <Grid item xs={12} md={6}>  
                <HomePageStarredEntities />  
              </Grid>  
              <Grid item xs={12} md={6}>  
                <HomePageToolkit tools={tools} />  
              </Grid>  
              <Grid item xs={12}> 


                Hi! Are you looking for a guide on how to use the tm catalog? <br />
                This page is still being workedon so to take a look at the following Backstage Docs. 
                <br />
                <br />
                For now have a picture of my friends cat instead!

                <link rel="icon" href="/docs/default/Component/tm-engineering-map" />
                
              </Grid>
              <Grid item xs={6}>
                <img style={{ width: "25%", height: "100%" }} src='/cat.jpg' />
              </Grid>
              <Grid item xs={12}>  
                <WarningBanner/>  
              </Grid>  
            </Grid>  
          </Grid>  
        </Content>  
      </Page>  
    </SearchContextProvider>  
  );  
};  