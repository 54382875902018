export const RING_TYPE = {
  ADOPT: 'adopt',
  TRIAL: 'trial',
  ASSESS: 'assess',
  HOLD: 'hold',
};

export const QUADRANT_TYPE = {
  INFRASTRUCTURE: 'infrastructure',
  FRAMEWORKS: 'frameworks',
  LANGUAGES: 'languages',
  PROCESS: 'process',
};
